import React from "react";

function FormInput({ ...inputprops }) {
  return (
    <div className="mb-4 relative rounded-md shadow-sm">
      <input
        {...inputprops}
        className="focus:ring-none focus:outline-none border-b-2 border-gray-light focus:border-gray block w-full px-5 py-3 sm:text-sm rounded-md"
      />
    </div>
  );
}

export default FormInput;
