import React from "react";
import { GrMail } from "react-icons/gr";
import { BsTelephoneFill } from "react-icons/bs";

function ContactCard() {
  return (
    // <div className="p-2 py-4 rounded-md bg-gray xl:mx-10 w-full h-64 sm:h-64 sm:w-64 m-4">
    <div className="p-3 rounded-md bg-gray w-full">
      <div className="flex flex-col justify-between h-full">
        <iframe
          title="location-map"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3301.552927696221!2d74.83983575129888!3d34.15777831951232!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x38e18469b4b38027%3A0x18004cecad5012be!2sFaizul%20Haq%20Colony%20Rd%2C%20Jeelnabad%2C%20Rainawari%2C%20Srinagar%2C%20190006!5e0!3m2!1sen!2sin!4v1635082417329!5m2!1sen!2sin"
          width="600"
          height="450"
          allowFullScreen={false}
          loading="lazy"
          className="w-full h-5/6 b-0 pb-2"
        ></iframe>
        <span
          aria-hidden="true"
          className="py-2 flex flex-row gap-x-2 items-center justify-center font-medium text-red-dark rounded-md"
        >
          <GrMail size="1.5em" /> support@dusksecurity.com
        </span>
        <span
          aria-hidden="true"
          className="py-2 flex flex-row gap-x-2 items-center justify-center font-medium text-red-dark rounded-md"
        >
          <BsTelephoneFill size="1.5em" /> +91 9419555914
        </span>
      </div>
    </div>
  );
}

export default ContactCard;
