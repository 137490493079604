import React from "react";
import ContactCard from "../components/contactCard";
import ContactCard2 from "../components/contactCard2";
import ContactForm from "../components/contactForm";
import Heading from "../components/heading/heading";
import Navbar from "../components/navbar";
import Seo from "../components/seo";
import Footer from "../components/footer/Footer";

function ContactUs() {
  return (
    <>
      <Seo
        title="Contact Us"
        description="Contact us for any queries or feedback"
      />
      <Navbar />
      <Heading>Contact Us</Heading>
      <div className="flex flex-col flex-col-reverse md:flex-row gap-x-5 items-stretch justify-between p-5 sm:px-10 lg:px-20 xl:px-36 md:py-10">
        <div className="w-full mt-10 md:mt-0 md:w-1/3 p-5 flex flex-col gap-y-6">
          <ContactCard />
          <ContactCard2 />
        </div>
        <div className="w-full md:w-7/12 flex justify-end">
          <ContactForm />
        </div>
      </div>
      <Footer/>
    </>
  );
}

export default ContactUs;
