import React, { useState } from "react";
import FormInput from "./formInput";
import useScript from "../hooks/useScript";

function sendMail(value) {
  const message = `Name:${value.name}<br/>Message: ${value.message}<br/>Phone: ${value.phone}<br/>Email: ${value.email}`;
  console.log(message);
  return window.Email.send({
    SecureToken: "b01375e3-78f2-442a-a3d4-6570794a24ce",
    To: `support@dusksecurity.com`,
    From: `secdusk@gmail.com`,
    Subject: `Message from ${value.name} through website.`,
    Body: message,
  })
    .then((m) => {
      alert("Message Sent Successfully")
    })
    .catch((e) => {
      alert("Message Failed to Send")
    });
}

function ContactForm() {
  useScript("https://smtpjs.com/v3/smtp.js");
  const [value, setValue] = useState({
    message: "",
    name: "",
    email: "",
    phone: "",
  });
  const onChangeHandle = (e) => {
    setValue({ ...value, [e.target.name]: e.target.value });
  };
  const onSubmitHandler = (e) => {
    e.preventDefault();
    sendMail(value).then(() => {
      setValue({
        message: "",
        name: "",
        email: "",
        phone: "",
      });
    });
  };
  return (
    <div className="flex flex-col w-full xl:max-w-2xl p-5 justify-between text-red-dark">
      <FormInput
        type="text"
        name="name"
        placeholder="Name"
        value={value.name}
        onChange={onChangeHandle}
      />
      <FormInput
        type="email"
        name="email"
        placeholder="Email"
        value={value.email}
        onChange={onChangeHandle}
      />
      <FormInput
        type="phone"
        name="phone"
        placeholder="Phone"
        value={value.phone}
        onChange={onChangeHandle}
      />
      <div className="mb-1 relative rounded-md shadow-sm">
        <textarea
          className="focus:ring-none focus:outline-none border-b-2 border-gray-light focus:border-gray block w-full px-4 py-3 sm:text-sm rounded-md"
          rows="6"
          name="message"
          placeholder="Message"
          onChange={onChangeHandle}
          value={value.message}
        />
      </div>
      <button
        className="w-max mx-auto mt-3 bg-red text-white p-2 px-8 hover:shadow-md rounded-md"
        onClick={onSubmitHandler}
      >
        Submit
      </button>
    </div>
  );
}

export default ContactForm;
