import React from "react";
import upi from "../images/upi.png";
import usdt from "../images/usdt.png";
import xrp from "../images/xrp.png";
import CopyText from "./copytext";

function ContactCard2() {
  const paymentInfo = {
    upi: "9419555914@paytm",
    usdt: "0xfbAC2b89DC59cD57abec3AC0B779c09294B9d655",
    xrp: "rUsde23YWgYoJP53c7M2oVpS3U8GgQEhaq",
  };
  return (
    <div className="p-3 rounded-md bg-gray w-full">
      <div className="text-2xl font-semibold text-red-dark flex justify-center mb-6">
        <span>Preferred Payment Methods</span>
      </div>
      <div className="flex flex-col h-full gap-y-2">
        <div className="flex gap-x-2 items-center justify-between">
          <div className="w-2/12">
            <img src={upi} alt="usdt" className="mx-auto block" />
          </div>
          <CopyText paymentInfo={paymentInfo.upi} />
        </div>
        <div className="flex gap-x-2 items-center justify-between">
          <div className="w-2/12">
            <img src={usdt} alt="usdt" className="mx-auto block w-1/2" />
          </div>
          <CopyText paymentInfo={paymentInfo.usdt} />
        </div>
        <div className="flex gap-x-2 items-center justify-between">
          <div className="w-2/12">
            <img src={xrp} alt="xrp" className="mx-auto block w-1/2" />
          </div>
          <CopyText paymentInfo={paymentInfo.xrp} />
        </div>
      </div>
    </div>
  );
}

export default ContactCard2;
