import React from "react";
import "./tooltip.scss";

function Tooltip({ children, active }) {
  return (
    <div className="tooltip-wrapper">
      {children}
      <div className={`tooltip-tip + ${active && "active"}`}>Copied!</div>
    </div>
  );
}

export default Tooltip;
