import React, { useState } from "react";
import { MdFileCopy } from "react-icons/md";
import Tooltip from "./tooltip/tooltip";

const CopyText = ({ paymentInfo }) => {
  const [active, setActive] = useState(false);

  let timeout;

  const showTip = () => {
    setActive(true);
    timeout = setTimeout(() => {
      hideTip();
    }, 1000);
  };

  const hideTip = () => {
    clearInterval(timeout);
    setActive(false);
  };

  return (
    <div className="w-10/12 py-2 px-4 mb-3 flex flex-row items-center justify-between text-sm font-regular rounded-md bg-gray-light">
      <div className="w-10/12 overflow-ellipsis overflow-hidden">
        {paymentInfo}
      </div>
      <div className="block w-2/12 flex justify-center">
        <Tooltip active={active}>
          <MdFileCopy
            className="w-full text-red-dark cursor-pointer"
            size="1.5em"
            onClick={() => {
              navigator.clipboard.writeText(paymentInfo);
              showTip();
            }}
          />
        </Tooltip>
      </div>
    </div>
  );
};

export default CopyText;
